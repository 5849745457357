const ENG = {
  //COMMON
  HOME: 'Home',
  BROADCAST_SCHEDULE: 'Broadcast Schedule',
  WATCH_THE_MATCH: 'Watch the Match',
  PREDICT_NOW: 'PREDICT NOW',
  PREDICT_NOW_BTN: 'Predict Now',
  LEADERBOARD: 'Leaderboard',
  HOW_TO_PLAY: 'How to Play',
  NEWS: 'News',
  CONTACT: 'Contact',
  LOGIN: 'Login',
  CLOSE: 'Close',
  FIGHT_WEEK: 'FIGHT WEEK ',
  LIVE_NOW: ' LIVE NOW',
  CONTINUE: 'Continue',
  USER_NAME: 'User Name',
  HOURS: ' hours',
  MIN: ' min',
  DAYS: ' days',
  MONTHS: ' months',
  YEARS: ' years',
  SIGN_OUT: 'SIGN OUT',
  MY_ACCOUNT: 'Profile & Points',
  USER_DOES_NOT_EXIST: "User doesn't exist",
  TOTAL: 'Total',
  //HOME
  HOME_TITLE: 'PREDICT THE FIGHT <br> WIN BIG.',
  HOME_TAGLINE:
    'Guess the outcome of the fight and be in <br> with the chance to win grand prizes each week.',
  HOME_BUTTON: 'Predict Now',
  HOME_BUTTON_PREDICT: 'PREDICT',
  HOME_BUTTON_NOW: 'NOW',
  HOME_READ_BEFORE_PREDICTING: 'Read Before Predicting',
  HOME_HOW_TO_PLAY: 'How To Play',
  HOW_TO_PLAY_NO_RECORD: "The next event's point allocation will be shown here when the event is visible on the Broadcast Schedule",
  HOME_READ_BEFORE_PREDICTING_TEXT: "Before submitting your predictions, make sure you're familiar with the Mola Fight Predictor points system:<br><br>" +
  "<ol>" +
  "<li>Correctly predicting the fight's outcome will earn you 3 points.</li><br>" + 
  "<li>Getting the victory method right - whether it's KO/TKO, Decision, or Submission - will grant you a total of 12 points (4 points multiplied by the outcome's base points).</li><br>" + 
  "<li>If you choose the right number of rounds the fight will last, you'll score a total of 9 points (3 points multiplied by the outcome's base points).</li><br>" + 
  "<li>If you successfully predict all three elements - the outcome, the method, and the number of rounds - you'll bag an extra bonus of 2 points. Therefore, if all your predictions for a fight are correct, your total points for that fight will be 24 points from the predictions plus 2 bonus points, making it a total of 26 points.</li><br>" + 
  "</ol>" +
  "Don't forget, there's a featured fight each week where you can earn quadruple points! If you're right about all aspects of this highlighted fight, your points will be multiplied by 4 from 24 to 96, and then you'll get an additional 2 points for accurately predicting all aspects - the outcome, the method, and the round - making the total points for the featured fight 98 points.<br><br>" +
  "Please be aware, any incorrect prediction of the fight's outcome will result in zero points for that fight, regardless of the accuracy of your method and round predictions.<br><br>" +
  "ATTENTION: Fight schedules on this website are subject to real-time event changes in accordance with the official timelines set by MMA organizations such as UFC, Bellator, Cage Warriors, etc. If a fighter changes after you've made a prediction, your prediction will be automatically cancelled. You'll need to submit a new prediction for the updated fight. Users can manually change their predictions until the submission deadline. It's each user's responsibility to monitor event updates and manage their predictions accordingly.",
  REWARD: 'Rewards',
  //BROADCAST
  BROADCAST_NOTES: 'Please, note that Prelims are in separate Tabs',
  BROADCAST_NOTES_MORE_EVENTS: 'Please, select an Event first.',
  BROADCAST_MAIN_CARD: 'Main Card',
  BROADCAST_PRELIMS: 'Prelims',
  BROADCAST_EARLY: 'Early Prelims',
  BROADCAST_MAIN_EVENT: 'Main Event',
  PLAYER_FAV: "MFP Player's Favourite",
  BROADCAST_POINTS_INFO: 'Points Info',
  BROADCAST_FIGHTER_RECORD: 'Fighter Record',
  BROADCAST_POINTS_ALLOCATION: 'Points allocation for this Event',
  BROADCAST_POINTS_ALLOCATION_NO_EVENT: 'Points allocation',
  BROADCAST_CORRECT_OUTCOME: 'Correct Outcome (Win / Draw):',
  BROADCAST_CORRECT_METHOD: 'Correct Method (KO/TKO etc.): ',
  BROADCAST_CORRECT_METHOD_SUBMISSION: 'Correct Method Submission: ',
  BROADCAST_CORRECT_METHOD_DECISION: 'Correct Method Decision: ',
  BROADCAST_BONUS: 'Bonus on Correct Outcome',
  BROADCAST_BONUS_POINTS: 'Points',
  BROADCAST_BONUS_ALL: 'Bonus on Correct Outcome, Method & Rounds:',
  BROADCAST_CORRECT_ROUND: 'Correct Nr. of Rounds: ',
  BROADCAST_MULTIPLIER_MAIN: 'Points Multiplier Main Card: ',
  BROADCAST_MULTIPLIER_PRELIMS: 'Points Multiplier Prelims: ',
  BROADCAST_MULTIPLIER_FEATURED: 'Points Multiplier Featured: ',
  BROADCAST_404: 'No predictions yet',
  BROADCAST_COUNTDOWN: 'Prediction submission will open on: ',
  BROADCAST_CLOSE_ON: 'Prediction submission closed on ',
  BROADCAST_AVAILABLE_SOON:
    'The Event has ended: The Leaderboard results will be available soon.',
  BROADCAST_END:
    'The Event has ended: The winning results can be seen on the Leaderboard.',
  BROADCAST_HOW: 'How?',
  BROADCAST_WHICH_ROUND: 'Which Round?',
  BROADCAST_HOW_2: 'How:',
  BROADCAST_WHICH_ROUND_2: 'Round:',
  BROADCAST_CANCELLED: 'This fight was cancelled!',
  BROADCAST_LOGIN: 'Login to Make Predictions',
  BROADCAST_EVENT_404: 'There are currently no upcoming Events.',
  BROADCAST_STAY_TUNED: 'Stay tuned!',
  FIGHTER_PROFILE: 'Fighter Profile',
  FIGHTER_SEE_MORE: 'See more details about this fighter here',
  SPONSORED: 'Special Fight',
  MFP_FAVOURITE: "MFP Players' Favourite",
  PREDICTIONS_FROM: 'Predictions from {x} users',
  PREDICTIONS_FROM_ONE: 'Predictions from 1 user',
  //RECAP
  RECAP_MON_RANK: 'Monthly Rank',
  RECAP_MON_POINT: 'Monthly Points',
  RECAP_WEEK_RANK: 'Weekly Rank',
  RECAP_WEEK_POINT: 'Weekly Points',
  //AUTH
  AUTH_LOGIN: 'Login to Mola - Fight Predictor',
  AUTH_EMAIL: 'Email',
  AUTH_PASSWORD: 'Password',
  AUTH_PASSWORD_HINT: 'Minimal 8 characters',
  AUTH_ALREADY_HAVE_ACCOUNT: 'Already have an account? Login with password.',
  AUTH_BUTTON: 'Login / Sign Up',
  AUTH_CONTINUE_WITH_GOOGLE: 'Continue with Google',
  AUTH_CONFIRM_EMAIL: 'Please check your email!',
  AUTH_CONFIRM_EMAIL_2: 'Your confirmation link already sent to your email!',
  AUTH_EMAIL_VALIDATION: 'E-mail must be valid',
  AUTH_ONE_STEP_AHEAD: 'One Step Ahead',
  AUTH_INVALID_EMAIL: 'Invalid email link!',
  AUTH_WELCOME_BACK:
    '"Welcome Back! Redirecting you to the homepage in a few seconds..."',
  //LEADERBOARD
  LEADERBOARD_MONTHLY_LEADERBOARD: 'MONTHLY LEADERBOARD',
  LEADERBOARD_WEEKLY_LEADERBOARD: 'WEEKLY LEADERBOARD',
  LEADERBOARD_WEEKLY_LEADERBOARD_FUN: 'WEEKLY FUN LEADERBOARD',
  LEADERBOARD_MONTHLY_REWARD: 'MONTHLY REWARD LEADERBOARD',
  LEADERBOARD_WEEKLY_REWARD: 'WEEKLY REWARD LEADERBOARD',
  LEADERBOARD_MONTHLY: 'Monthly',
  LEADERBOARD_WEEKLY: 'Weekly',
  LEADERBOARD_MONTHLY_FUN: 'Monthly Fun',
  LEADERBOARD_WEEKLY_FUN: 'Weekly Fun',
  LEADERBOARD_REWARD_MONTHLY: 'Monthly Reward',
  LEADERBOARD_REWARD_WEEKLY: 'Weekly Reward',
  LEADERBOARD_RANK: 'Rank',
  LEADERBOARD_TOTAL_POINTS: 'Total Points',
  LEADERBOARD_TOTAL_MOLA_POINTS: 'Total Mola Points',
  LEADERBOARD_POINTS: 'Points',
  LEADERBOARD_CORRECT_OUTCOME: 'Correct Outcome',
  LEADERBOARD_CORRECT_METHOD: 'Correct Method',
  LEADERBOARD_CORRECT_ROUNDS: 'Correct Rounds',
  LIVE_EVENT_LEADERBOARD: 'Live Event Leaderboard',
  LEADERBOARD_DETAILS_FOR: 'Details for',
  LEADERBOARD_MY_RANKING: 'My Ranking',
  LEADERBOARD_NO_RANKING: 'No Ranking',
  //PREDICTION
  PREDICTION: ' Prediction',
  PREDICTION_GAME: 'Game',
  PREDICTION_GAME_DATE: 'Date',
  PREDICTION_OUTCOME: 'Outcome',
  PREDICTION_METHOD: 'Method',
  PREDICTION_ROUNDS: 'Rounds',
  PREDICTION_SUBMISSION: 'Submission',
  PREDICTION_DECISION: 'Decision',
  PREDICTION_LOSES: 'Loses',
  PREDICTION_WIN: 'Win',
  PREDICTION_WINNER: 'Winner',
  PREDICTION_DRAW: 'Draw',
  YOUR_PREDICTION: 'Your Prediction',
  YOUR_PREDICTION_1: 'Your',
  YOUR_PREDICTION_2: 'Prediction',
  COPY_PREDICTION: 'Copy or download your prediction',
  SHARE_PREDICTION: 'Share on social media',
  WHICH_ROUND: 'Which Round?',
  SUBMIT_PREDICTION: 'Submit Prediction',
  UPDATE_PREDIDCTION: 'Update Prediction',
  CHANGE_PREDIDCTION: 'Change Prediction',
  INFO_PREDICTION: 'Your Prediction has been Submitted.',
  PREDICTION_CLOSE_IN: 'Prediction submission will close on ',
  IN_X_DAYS: 'in {x} days',
  //CONTACT
  CONTACT_US: 'Contact Us',
  CONTACT_MESSAGE:
    'Please don’t hesitate to contact us if you need further information or have other issues.',
  CONTACT_BUTTON: 'Send us an Email',
  //NEWS
  NEWS_MFP: 'SUPERCHALLENGE NEWS',
  NEWS_VIEWS: ' Views',
  NEWS_VIEW: ' Views',
  NEWS_SHARE: 'Share',
  NEWS_DAYS_AGO: ' Days Ago',
  NEWS_SHARE_VIA: 'Share Via',
  // FAQ
  FAQ: 'FAQ',
  FAQ_LONG: 'FREQUENTLY ASKED QUESTIONS',
  FAQ_TITLE_01: '1. About SuperChallenge Fight Predictor?',
  FAQ_TITLE_02: '2. About Mola Subscriptions?',
  FAQ_TITLE_03: '3. Mola Catalog and Prize Redemptions?',
  FAQ_TITLE_04: '4. Contact and Support',
  //MY ACCOUNT HOME
  MY_ACCOUNT_WELCOME: 'Welcome',
  MY_ACCOUNT_MOLA_POINTS: 'Points',
  MY_ACCOUNT_POINTS_WON: 'Weekly Points Won',
  MY_ACCOUNT_SWAP: 'Swap Requests',
  MY_ACCOUNT_PROFILE: 'My Profile',
  //PROFILE
  MY_PROFILE: 'My Profile',
  MY_PROFILE_ACCOUNT_VERIFICATION: 'Account Verification',
  MY_PROFILE_ACCOUNT_EMAIL: 'Account Email',
  MY_PROFILE_PASSWORD: 'Password',
  MY_PROFILE_ACCOUNT_INFORMATION: 'Account Information',
  MY_PROFILE_ACCOUNT_TYPE: 'Account Type',
  MY_PROFILE_ACCOUNT_STATUS: 'Account Status',
  MY_PROFILE_MOLA_SUBSCRIPTION: 'Mola Subscription',
  MY_PROFILE_ACTIVE: 'Active',
  MY_PROFILE_NOT_MOLA_SUBSCRIBER: 'Not a Mola Subscriber',
  MY_PROFILE_FREE: 'Free',
  MY_PROFILE_PERSONAL_INFORMATION: 'Personal Information',
  MY_PROFILE_DISPLAY_NAME: 'Display Name',
  MY_PROFILE_ABOUT_ME: 'About me',
  MY_PROFILE_FULL_LEGAL_NAME: 'Full Legal Name',
  MY_PROFILE_DOB: 'Date of Birth',
  MY_PROFILE_GENDER: 'Gender',
  MY_PROFILE_ADDRESS_DETAILS: 'Address Details',
  MY_PROFILE_LANGUAGE: 'Language',
  MY_PROFILE_CURRENT_VERSION: 'Current Version',
  MY_PROFILE_TANDC: 'Terms and Conditions',
  MY_PROFILE_PRIVACY_POLICY: 'Privacy Policy',
  MY_PROFILE_UPGRADE_NOW: 'Upgrade Now',
  MY_PROFILE_SUBSCRIBE_NOW: 'Subscribe Now',
  MY_PROFILE_SIGN_OUT: 'Sign Out from My Acccount',
  MY_PROFILE_BUTTON_CHANGE: 'Change',
  MY_PROFILE_ABOUT: '',
  MY_PROFILE_DOB_TEXT: 'Input your date of birth',
  MY_PROFILE_GENDER_NOT_SET: 'NOT SET',
  MY_PROFILE_GENDER_M: 'MALE',
  MY_PROFILE_GENDER_F: 'FEMALE',
  MY_PROFILE_GENDER_O: 'OTHERS',
  MY_PROFILE_PASSWORD_SET: 'Please, set your password',
  MY_PROFILE_NEW_EMAIL: 'Please, set your password',
  MY_PROFILE_CHECK_EMAIL: '',
  MY_PROFILE_EMAIL_INSTRUCTIONS: '',
  MY_PROFILE_BUT_CHANGE_EMAIL: 'Change Email',
  MY_PROFILE_ACC_NOT_VERIFIED: '',
  MY_PROFILE_ACC_VERIFIED: '',
  MY_PROFILE_ACC_BTN_VERIFY: '',
  MY_PROFILE_ACC_VERIFY: '',
  MY_PROFILE_PASSWORD_SUGGESTION: 'Strong Password Suggestions',
  MY_PROFILE_PASSWORD_SUGGESTION_TEXT: `
    &bull; At least 8 characters—the more characters, the better.<br />
    &bull; A mixture of both uppercase and lowercase letters.<br />
    &bull; A mixture of letters and numbers.<br />
    &bull; Inclusion of at least one special character, e.g., ! @ # ? ]
  `,
  ACC_EMAIL_NOT_SET: 'Your email adress has not been set',
  ACC_EMAIL_ASS:
    'We have assigned you a temporary email address that is used for auhtentication together with your connected wallet.',
  // SETTINGS UPDATED
  SETTINGS_UPDATED_DISPLAYNAME: 'Display Name Updated',
  SETTINGS_UPDATED_ABOUTME: 'About Me Updated',
  SETTINGS_UPDATED_FULLNAME: 'Full Name Updated',
  SETTINGS_UPDATED_DOB: 'Date of Birth Updated',
  SETTINGS_UPDATED_GENDER: 'Gender Updated',
  SETTINGS_UPDATED_ADDRESS: 'Address Updated',
  SETTINGS_UPDATED_LANGUAGE: 'Language Updated',
  SETTINGS_UPDATED_PHOTO: 'Profile Picture Updated',
  //POINTS
  POINTS_NON_MOLA:
    'Because you are a free Mola subscriber, the points won cannot be redeemed. To be able redeem your points, upgrade your Mola Subscription now!',
  POINTS_FOR: 'Points won for last event',
  POINTS_404: 'No results or points won for this events fights',
  POINTS_MORE: 'Load More from Past Events',
  POINTS_HISTORY: 'Points Won History',
  POINTS_HISTORY_404: 'No history of points won',
  //ADMIN
  ADMIN_DASHBOARD: 'Admin Dashboard KPI',
  REPORTING_DASHBOARD: 'Reporting Dashboard',
  GAME_MANAGEMENT: 'Game Management',
  ADMIN_MANAGEMENT: 'Admin Tasks',
  EVENTS_AND_GAMES: 'Events & Games',
  GAME_RESULTS: 'Game Results',
  CREATE_EVENT: 'Create Event',
  NEWS_MANAGEMENT: 'News Management',
  HOME_MANAGEMENT: 'Home Management',
  WATCH_MANAGEMENT: 'Watch The Match Management',
  REWARD_MANAGEMENT: 'Reward Management',
  WATCH_MATCH_FREE: 'Watch the match for free',
  WATCH_ON_MOLA: 'Watch on Mola',
  POINTS_AND_TOKEN_MANAGEMENT: 'Points & Token Management',
  POINTS_MANAGEMENT: 'Points Management',
  SWAP_REQUEST: 'Swap Request',
  MANAGE_EVENTS_AND_GAMES: 'Manage Events and Games',
  PROCESS_GAME_RESULTS: 'Process Game Results',
  CREATE_NEW_PREDICTION_GAME:
    'Create a New Prediction Game for Event and Fights',
  MANAGE_NEWS: 'Manage News',
  //DASHBOARD
  ADMIN_TOTAL_SIGN_UPS: 'Total Sign Ups',
  ADMIN_TOTAL_SIGN_UPS_TODAY: 'Total Sign Ups Today',
  ADMIN_TOTAL_SIGN_UPS_THIS_MONTH: 'Total Sign Ups This Month',
  ADMIN_TOTAL_MOLA_SUBSCRIBERS: 'Total Mola Subscribers',
  ADMIN_TOTAL_PREDICTIONS: 'Total Predictions',
  ADMIN_TOTAL_POINTS_WON: 'Total Points Won',
  DATE: 'Date',
  EVENT: 'Event',
  FIGHT: 'Fight',
  ROWS_PER_PAGE: 'Rows per page',
  ROWS_PER_PAGE_OF: 'of',
  ROWS_PER_PAGE_ALL: 'All',
  //FOOTER
  FOOTER_TITLE: 'PREDICT THE FIGHT WIN BIG.',
  FOOTER_TITLE_NEW: 'SUBSCRIBE MOLA',
  MOLA_FIGHT_CLUB: 'SuperChallenge Fight Predictor',
  ABOUT: 'About Mola Digital',
  SOCIAL: 'Social Networks',
  //Register
  REGISTER_WELCOME_TO_MFP: 'Welcome to Mola - Fight Predictor',
  REGISTER_LOGGED: 'You are logged in!',
  REGISTER_CONTINUE: 'Continue to complete your account creation',
  REGISTER_COMPLETE: 'Complete your Account',
  REGISTER_IN_ORDER:
    'In order to complete the setup of your account, we need a little more information from you',
  REGISTER_FULL_NAME: 'Full Name',
  REGISTER_PASSWORD: 'Password',
  REGISTER_SUBMIT: 'Submit',
  REGISTER_WELCOME: 'Welcome',
  REGISTER_WELCOME_BACK: 'Welcome Back',
  REGISTER_THANKS:
    'Thank you for joining us. You will be redirected to the homepage...',
  REGISTER_REDIRECT: 'Redirecting you to the homepage in a few seconds...',
  //Authorise
  LOADING: 'Loading...',
  LOADING_LEADERBOARD_DATA: 'Loading Leaderboard Data...',
  ERROR: 'Error...',
  PLEASE_WAIT: 'Please wait in a few seconds...',
  BACK_TO_HOME: 'Back To Home',
  LOCAL_TIME: 'Local Time',
  LEADERBOARD_TITLE: 'Leaderboard',
  AUTH_MESSAGE: `Kode tindakan tidak valid. Ini bisa
     terjadi jika kode salah bentuk, kedaluwarsa, atau
     telah digunakan.`,
  //New
  MOLA_CATALOG: 'Redeem Points',
  TO_MOLA_CATALOG_ADMIN: 'To Mola Catalog Admin',
  ALL_FIGHTS: 'All Fights',
  MEMBER_SINCE: 'Member Since',
  PROFILE_SCORE: 'Profile Score',
  VERIFIED: 'Verified',
  NOT_VERIFIED: 'Not Verified',
  VERIFY: 'Verify',
  IMA: `I'm a `,
  STARTER: 'Starter',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  SUSPENDED: 'Suspended',
  HOW_TO_PLAY_TEXT: "<ol>" +
  "<li>Click the 'Login' button and click 'Register' to register yourself.</li><br>" + 
  "<li>If you are an active Mola subscriber, please make sure you use the same email for registration as the email used to subscribe to Mola. If you are not an active Mola subscriber, you can simply enter your email address.</li><br>" + 
  "<li>Complete your personal details on the 'My Profile' page.</li><br>" + 
  "<li>Enter your predictions for the upcoming event. Starting from the fighter who wins, the method to win and the round the fight ends. Make sure you predict all available fights to earn maximum points.</li><br>" + 
  "<li>If you want to change your predictions, you can do so directly on the Predictions page and click 'Change Prediction'.</li><br>" + 
  "<li>Watch the event on Mola and find out the results.</li><br>" + 
  "<li>When the fight ends, you can view your 'Weekly Points' on the profile page and check your ranking on the 'Leaderboard' page.</li><br>" + 
  "<li>SuperChallenge Fight Predictor is accessible to anyone, but make sure you are an active Mola subscriber if you want to earn “Mola Points”, which you can later redeem for exclusive rewards available in the Mola Catalog.</li><br>" +
  "</ol>",
  REDEEM_NOW: 'Redeem Now!',
  LOAD_MORE: 'Load more',
  PREDICTION_ALERT: 'Please, make sure to fill out all the  <strong>prediction</strong> fields above.',
  SECONDS_AGO: ' seconds ago',
  MINUTES_AGO: ' minutes ago',
  HOURS_AGO: ' hours ago',
  DAYS_AGO: ' days ago',
  MONTHS_AGO: ' months ago',
  YEARS_AGO: ' years ago',
  BIRTHDAY: 'Birthday',
  CITY: 'City',
  POSTAL_CODE: 'Postal Code',
  // Mola Points and Weekly Points Won - Quick Guide
  QUICK_GUIDE_TITLE: 'Mola Points and Weekly Points Won - Quick Guide',
  QUICK_GUIDE_SECTION_01: 'Weekly Points Won',
  QUICK_GUIDE_SECTION_01_TEXT: 'Earn Weekly points for every correct prediction on our site. These points are largely just for fun, though this can give you a competitive edge in our special events in the future. All users are qualified to earn Weekly Points.',
  QUICK_GUIDE_SECTION_02: 'Mola Points',
  QUICK_GUIDE_SECTION_02_TEXT: 'Exclusive to users with active Mola subscription. In addition to the Weekly Points Won, the same amount will be automatically converted into Mola Points and will be added to your Mola Points balance. Earn these points, in addition to Weekly Points Won, by winning your predictions. Make sure the email address you use to predict is the same as your Mola subscription email. Remember, your Mola subscription must be active before you submit predictions.',
  QUICK_GUIDE_SECTION_03: 'Redeeming Points',
  QUICK_GUIDE_SECTION_03_TEXT: 'Redeem Mola Points for prizes from our Mola Catalog. Go to the Mola Catalog or click "Redeem Now" in the "Mola Points" menu to exchange points. <strong>Only Mola Points can be used for redemption.</strong>',
  DATA_404: 'No data available',
  LIVE_LEADERBOARD: 'Live Event Leaderboard',
  NEXT_FIGHT_UP: 'Next Fight Up:',
  EVENT_END: 'This Event has ended',
  NO_RESULT: 'There are no results yet.<br>The first fight still has to start or is currrently ongoing.',
  NICKNAME: 'Nickname: ',
  AGE: 'Age: ',
  DOB: 'Date of Birth: ',
  GENDER: 'Gender: ',
  WINS: 'Wins',
  LOSSES: 'Losses',
  DRAWS: 'Draws',
  MINUTES: ' minutes',
  SECONDS: ' seconds',
  IN_HOURS_AND_MIN: '{x} hours and {y} min',
  SORT_BY_DATE: 'Sort By Date',

  EMPTY: `{variable-name} can't be empty`,
  MINIMUM_LENGTH: `{variable-name} minimum length is {number}`,

  CHANGE_YOUR_PASSWORD: 'Change Your Password',
  NEW_PASSWORD: 'New Password',
  CURRENT_PASSWORD: 'Current Password',
  SET_NEW_PASSWORD: 'Set new Password',
  CLICK_TO_SELECT_A_PHOTO: 'Click to select a photo',
  CHANGE_PHOTO: 'Change Photo',
  REMOVE_PHOTO: 'Remove Photo',
  SAVE: 'Save',
  SET_PICTURE: 'Set Picture',
  TAP_TO_SELECT_PHOTO: 'Tap here to select a photo <br>from your gallery',
  AGREE: 'By Signing In, you have read and agreed to the',
  AND: 'and',
  DOWNLOAD_PLAYSTORE: 'Download Mola on Playstore',
  DOWNLOAD_APPSTORE: 'Download Mola on Appstore',
  INSTAGRAM: 'Instagram @mola.sport',
  TIKTOK: 'Tiktok @mola.sport',

  // Duelling
  DUEL_PROVE: `
    Prove that you are better than them!
    <br/>
    If you get higher points on your predictions than your opponent,
    You will get all of their mola points from this week's prediction
  `,
  DUEL_TITLE: 'DUEL A FRIEND',
  INVITATION_SENT: 'INVITATION SENT',
  HOW_IT_WORKS: 'How it works?',
  WHOS_YOUR_OPPONENT: `WHO'S YOUR OPPONENT?`,
  SEARCH_OPPONENT: 'Search your opponent username',
  SEARCH_OPPONENT_2: 'Search your opponent username',
  SEND_DUEL_INVITATION: 'SEND DUEL INVITATION',
  DUEL_CONFIRM: 'Please check your Notification Later!',
  DUEL_CAPTION: 'Your duel invitation has been sent to your opponent! Once your opponent accepts or rejects, you will be notified!',
  DUEL_USER_ALREADY_INVITED: ' has already been invited to another duel',
  FROM: 'From',
  TO: 'To',
  DUEL_BUTTON: 'Duel With Your Friend',
  SHARE_BUTTON: 'Share Your Prediction',
  DUEL_SHARE_INFO: 'Make sure you have submitted predictions for the 5 matches above for the " Share your Prediction" and "Duel with your Friend" features.',
  DUEL_WIN: 'Win in Prediction Duel ',
  DUEL_LOSE: 'Lose in Prediction Duel ',
  DUEL_DRAW: 'Draw in Prediction Duel ',
  DUEL_WIN_WITH: 'Win in Prediction Duel with ',
  DUEL_LOSE_WITH: 'Lose in Prediction Duel with ',
  DUEL_DRAW_WITH: 'Draw in Prediction Duel with ',
  OPPONENT_NOT_MOLA_SUBSCRIPTION: 'Opponent Not a Mola Subscriber',
  DUEL_USER_MUST_BE_MOLA_SUBSCRIPTION: 'You are not a Mola Subscriber',
  ACCEPT: 'ACCEPT',
  REJECT: 'REJECT',
  ACCEPT_DUEL: 'This Invitation was Accepted',
  REJECT_DUEL: 'This Invitation was Rejected',
  ACCEPT_DUEL_SENDER: 'This Invitation was Accepted',
  REJECT_DUEL_SENDER: 'This Invitation was Rejected',
  // Refer your friends
  MY_REFERRAL_EARNINGS: 'MY REFERRAL EARNINGS',
  REFERAL_CODE: 'Referral Code (optional)',
  INVALID_CODE: 'Invalid Referal Code!',
  CHECK_CODE: 'Please, check your code or continue without.',
  CONGRATULATIONS: 'Congratulations!', 
  FIRST_PREDICTION_TEXT1: 'You have made your 1st prediction.',
  FIRST_PREDICTION_TEXT2: 'You and your friend, who invited you to MFP, have just received 5 Mola Points each.',
  CONTINUE_PREDICTING: 'Continue Predicting',
  COPY_TO_CLIPBOARD: 'Copy to Clipboard',
  REFER_YOUR_FRIENDS: 'REFER YOUR FRIENDS',
  REFER_YOUR_FRIENDS_1: 'REFER',
  REFER_YOUR_FRIENDS_2: 'YOUR FRIENDS',
  REFER_NOW: 'Refer Now',
  INVITE_TEXT1: 'Invite your friends to MFP!',
  INVITE_TEXT2: 'You and your friend will get <span style="color:#f8df8e">5 POINTS</span>',
  SHARE_CODE: 'Share your referral code <strong>"{x}"</strong> to give your friends 5 Mola Points. When they submit their first prediction, you get 5 Mola Points too!',
  COPY_CODE: 'Hey! Let\'s compete on Mola Fight Predictor! You and I will get 5 Mola Points when you enter my referral code <strong style="color: #2196F3">"{x}"</strong> and submit your first prediction. Sign up in <a href="https://molafightclub.com">here<a/>',
  COPY_CODE_PLAIN: 'Hey! Let\'s compete on Mola Fight Predictor! You and I will get 5 Mola Points when you enter my referral code "{x}" and submit your first prediction. Sign up on https://molafightclub.com',
  YOU_ALREADY_DUEL: 'You are Already in Duel with:',
  SUCCESS_COPY_IMAGE: 'Image copied to clipboard successfully!',
  NOTIFICATIONS: 'NOTIFICATIONS',
  INBOX: 'Inbox',
  OUTBOX: 'Outbox',
  SENT: 'Sent',
  FALLBACK_SHARE: 'Fitur berbagi tidak didukung di browser ini, silakan gunakan opsi unduh',

  MY_ITEM : 'My Item',
  HOMEPAGE_MANAGEMENT : 'Homepage Management',
  LIVE_BUTTON_LINK : 'Live Button Link',
  WATCH_THE_MATCH_LINK : 'Watch The Match Link',
  HOME_CONFIG_TITLE : 'Home Title',
  HOME_CONFIG_TAGLINE : 'Home Tagline',
  CAROUSEL_IMAGES_AND_LINKS : 'Carousel Images and Links',
  CAROUSEL_INTERVAL : 'Carousel Interval',
  SLIDE_TIME_IN_SECONDS : 'Slide time in seconds.',
  HOME_BANNER_DESKTOP : 'Home Banner Desktop',
  HOME_BANNER_DESKTOP_LINK : 'Home Banner Desktop Link',
  URL_WHEN_BANNER_IS_CLICKED : 'url when banner is clicked',
  HOME_BANNER_MOBILE : 'Home Banner Mobile',
  HOME_BANNER_MOBILE_LINK : 'Home Banner Mobile Link',
  PREVIEW : 'Preview',
  SAVE_CONFIGURATION : 'Save Configuration',

  TITLE_AT_HOME_BANNER : 'Title at Home Banner',
  ENTER_TITLE_FOR_HOME_BANNER : 'Hint: Enter Title for home banner',
  SET_TITLE_TO_BLANK : 'Set Title to Blank',
  SET_TITLE_TO_BLANK_WITHOUT_DELETE_ANYTHING : 'Set title to blank without delete anything',
  TAGLINE_AT_HOME_BANNER : 'Tagline at Home Banner',
  ENTER_TAGLINE_FOR_HOME_BANNER : 'Hint: Enter tagline for home banner',
  SET_TAGLINE_TO_BLANK : 'Set Tagline to Blank',
  SET_TAGLINE_TO_BLANK_WITHOUT_DELETE_ANYTHING : 'Set tag to blank without delete anything',


  WATCH_LIVE_BUTTON_LINK : 'Watch Live Button Link',
  PAGE_TITLE : 'Page Title',
  TITLE_AT_WATCH_THE_MATCH_PAGE : 'Title at Watch the match page',
  ENTER_TITLE_FOR_WATCH_THE_MATCH_PAGE : 'Hint: Enter title for Watch the match page',
  WATCH_MATCH_TAGLINE : 'Watch Match Tagline',
  BACKGROUND_IMAGES : 'Background Images',
  DESKTOP_BACKGROUND_IMAGE : 'Desktop Background Image',
  MOBILE_BACKGROUND_IMAGE : 'Mobile Background Image',


  SPECIAL_PRIZE_TITLE : 'Special Prize Title',
  PRIZE_CATALOG_TITLE : 'Prize Catalog Title',
  BACKGROUND_IMAGE_LINK : 'Background Image Link',
  URL_WHEN_BACKGROUND_IMAGE_IS_CLICKED : 'url when background image is clicked',
  TITLE_AT_REWARD_PAGE : 'Title at Reward Page',
  TITLE_AT_SPECIAL_PRIZE : 'Title at Special Prize',
  TITLE_AT_PRIZE_CATALOG : 'Title at Prize Catalog',

  MOLA_SUBSCRIBER_REPORT : 'Mola Subscriber Report',
  SUBSCRIBER_TYPE : 'Subscriber Type',
  GET_DATA : 'Get Data',
  ADMIN_ACTIONS : 'Admin Actions',

  CREATE_NEXT_MONTHS_LEADERBOARD : 'Create Next Months Leaderboard',
  MONTH_BEFORE : 'Month Before',
  MONTH_AFTER : 'Month After',
  CREATE : 'Create',
  PREDICTION_AND_POINTS_REPORT : 'Prediction and Points Report',
  CHOOSE_EVENT_FOR_GET_REPORT : 'Choose Event for Get Report',

  REAL_TIME_DUEL_MONITORING : 'Real-Time Duel Monitoring',
  REAL_TIME_DUEL_MONITORING_NOT_ACTIVE: 'There are currently no active Events',

  PLAYER_INSIGHT_REPORTS: 'Player Insight Reports',
  PLAYER_NEW_REPORT: 'Weekly New Player',
  PLAYER_REDEMPTION_REPORT: 'Weekly Points Redemption',

  CLICK_ON_A_BOX_TO_GET_DUEL_DETAILS : 'Click on a box to get Duel Details',
  DUELS_ACCEPTED : 'Duels Accepted',
  DUEL_INVITES_PENDING : 'Duel Invites Pending',
  DUELS_REJECTED : 'Duels Rejected',
  WEEKLY_DUEL_SUMMARY_REPORT : 'Weekly Duel Summary Report',
  SELECT_AN_EVENT : 'Select an Event',
  LOAD_SUMMARY_REPORT : 'Load Summary Report',
  LOAD_DASHBOARD_REPORT: 'Load Dashboard Report',
  LOAD_ALL_TIME_REPORT: 'Load All Time Report',
  DOWNLOAD_EXCEL: 'Download Excel',
  MONTHLY_LEADERBOARD_DEFAULT_VIEW : 'Monthly Leaderboard Default View',
  LEADERBOARD_CONFIGURATION: 'Leaderboard Configuration',
  REPORT_DETAIL_PER_USER: 'Report Detail Per User',
  PREDICTION_WINNERS_REPORT : 'Prediction Winners Report',
  CHOOSE_EVENT_FOR_THE_REPORT : 'Choose Event for the Report',
  CHOOSE_FIGHT_FOR_THE_REPORT : 'Choose Fight for the Report',
  DUEL_DASHBOARD : 'Duel Dashboard',
  WATCH_THE_MATCH_MANAGEMENT : 'Watch The Match Management',
  ADMIN_TASKS : 'Admin Tasks',
  WEEKLY_DUEL_SUMMARY : 'Weekly Duel Summary',
  POINT_CORRECTION : 'Point Correction',
  REPORTING : 'Reporting',
  PREDICTION_ANALYTICS : 'Prediction Analytics',
  SUBSCRIBER_REPORT : 'Subscriber Report',
  LEADERBOARD_CONFIG : 'Leaderboard Config',
  DESKTOP_BACKGROUND_IMAGE_URL : 'Desktop Background Image link',
  MOBILE_BACKGROUND_IMAGE_URL : 'Mobile Background Image link',
  TAGLINE_AT_WATCH_MATCH: 'Tagline at Watch Match',
  AVERAGE_PREDICTION_TIME: 'Average Prediction Time All Users',
  CHOOSE_EVENT_FOR_GET_REPORT_BR: 'Choose Event for <br/> Get Report',

  SHOWING_LAST_ITEMS : 'Showing Last ({x}) items',
  TYPE_TO_SEARCH_OR_FILTER : 'Type to Search or Filter',
  SELECT_TYPE : 'Select Type',
  EVENT_DETAILS : 'Event Details',
  SELECT_ADD_FIGHTS : 'Select/Add Fights',
  LOAD_FIGHTER_PROFILES : 'Load Fighter Profiles',
  BACK: 'Back',
  CANCEL: 'Cancel',
  DUEL_FILTER: 'Click on a box to filter Duel Details',

  CREATE_EVENT_PREDICTION_GAME : 'Create Event Prediction Game',
  LOAD_FIGHTER_PROFILES_HINT : 'In order to load all the Fighter\'s Profile, they need to be loaded from an external API.<br> Please continue by clicking "Get Fighter Profiles".',
  GET_FIGHTER_PROFILES : 'Get Fighter Profiles',
  CONTINUE_ADD_FIGHTER_PROFILES : 'Continue Add Fighter Profiles',
  CREATE_ANOTHER_EVENT : 'Create Another Event',
  MANAGE_THIS_EVENT_IN_EVENTS : 'You can Manage this event in \'Events\'.',
  THIS_EVENT : 'This event.',
  EVENT_CREATED : 'EVENT CREATED',
  SELECT_AN_UFC_EVENT : 'Select an UFC Event',
  MULTIPLIER_MAIN_CARDS : 'Multiplier Main Cards',
  MULTIPLIER_PRELIMS : 'Multiplier Prelims',
  MULTIPLIER_FEATURED_SPECIAL : 'Multiplier Featured/Special',
  FIGHTS_SELECTED_FOR_PREDICTION_GAMES : 'Fights Selected for Prediction Games',
  FIGHTS : 'Fights',
  NR_OF_ROUNDS : 'Nr. of Rounds',
  PREDICTION_GAME_SETUP_SUMMARY : 'Prediction Game Setup Summary',
  START_DATE : 'Start Date',
  END_DATE : 'End Date',
  VENUE : 'Venue',
  SHOW_ON_BROADCAST_SCHEDULE_FROM : 'Show on Broadcast Schedule From',
  PREDICTION_SUBMISSION_OPEN_FROM : 'Prediction Submission Open From',
  MAKE_SPECIAL_FIGHT : 'Make Special Fight',
  NR_OF_ROUNDS_SCHEDULED : 'Nr. of Rounds Scheduled',
  EVENT_NAME : 'Event Name',
  EVENT_TYPE : 'Event Type',
  UPLOAD_SPONSOR_LOGO : 'Upload Sponsor Logo',
  UPDATE_FIGHT_TO_SPECIAL : 'Update Fight to Special',
  USE_THIS_IMAGE : 'Use this Image',
  CREATE_FIGHTER_PROFILE : 'Create Fighter Profile',
  MAKE_THIS_A_SPECIAL_FIGHT : 'Make this a Special Fight',
  ADD_SPONSOR_LOGO : 'Add Sponsor Logo',
  EVENT_PUBLISH_END : 'Event Publish End',
  EVENT_PUBLISH_START : 'Event Publish Start',
  ORDERING : 'Ordering',
  PREDICTION_SUBMISSION_START : 'Prediction Submission Start',
  PREDICTION_SUBMISSION_END : 'Prediction Submission End',
  BROADCAST_SCHEDULE_DETAILS : 'Broadcast Schedule Details',
  POINTS_ALLOCATION_AND_CONFIGURATION : 'Points Allocation and Configuration',
  COMBINE_EVENTS : 'Combine Events',
  SELECT_DATE : 'Select Date',
  SELECT_TIME : 'Select Time',
  SCHEDULED_FIGHTS : 'Scheduled Fights',
  SELECT_ALL : 'Select All',
  HOW_TO_PLAY_CONTEST: 'How to Enter the Contest',
  HOW_TO_PLAY_MFP: 'HOW TO PLAY MOLA FIGHT PREDICTOR',
  HOW_TO_WIN_SUPERTRIP: 'HOW TO ENTER MFP SUPERTRIP:',
  HOW_TO_WIN_SUPERTRIP_TEXT: `
  <ol>
  <li>Participants must be legal Indonesian citizens and at least 18 years old.</li>
  <li>Each participant is only allowed one entry per person. Multiple entries by the same individual will result in disqualification.</li>
  <li>The SuperTrip MFP program period starts from June 1 to June 30 2024.</li>
  <li>Points are awarded based on the accuracy of predictions following how to play Mola Fight Predictor.</li>
  <li>In the event of a tie for highest points of the week, the following tiebreaker rules apply:
  <ul>
  <li>Participant with the highest number of correct predictions.</li>
  <li>If it is still a tie, the participant who submits his prediction earlier.</li>
  </ul>
  </li>
  <li>Two winners will be selected based on the highest weekly scores at the end of the month on June 30 when the program ends, once the points results from all 5 events in June are available.</li>
  <li>Points do not accumulate from week to week. Only the weekly highest scores are taken into consideration.</li>
  <li>The more often you make predictions, the greater your chances of getting the highest points for the week.</li>
  <li>The organizer has the right to verify the eligibility of participants' passports based on the passport expiry date, namely at least January 2025.</li>
  <li>Visa will be prepared by the organizer for winners who require it.</li>
  <li>The Organizer reserves the right to verify the identity of participants and disqualify participants suspected of having multiple entries or collusion.</li>
  <li>Weekly scores and rankings will be displayed every week on the Leaderboard.</li>
  <li>Participants will be informed of their scores and rankings at the end of each week via email.</li>
  <li>The organizer reserves the right to change or stop the program at any time due to unforeseen circumstances. Any changes will be immediately notified to participants.</li>
  <li>Travel prizes to Perth for UFC events include round trip plane tickets to home city - Perth, hotel accommodation during the trip, and UFC event tickets.</li>
  <li>The prize is valid for 1 (one) person and cannot be transferred or exchanged for cash.</li>
  <li>For questions or support regarding the contest, participants can contact support@mola.tv.</li>
  </ol>`,
  DUELS_EXPIRED: 'Duel Expired',

  // PLAYER REPORTS
  SELECT_DATES: 'Select Dates From - To',
  WEEKLY_REDEMPTION_REPORT: 'Weekly Redeemed and Unredeemed Points',
  LOAD_REDEMPTION_REPORT : 'Load Redemption Report',
  TOTAL_UNREDEEMED_POINTS: 'Total Unredeemed Points',
  TOTAL_REDEEMED_POINTS: 'Total Redeemed Points',
  TOTAL_REDEEMED_ORDERS: 'Total Redeem Orders',
  TOTAL_REDEEMED_ORDERS_QUANTITY: 'Total Items Redeemed',
  PLAYER_REPORT_FILTER: 'Click on a box to filter Details',
  WEEKLY_NEW_PLAYER_REPORT: 'Weekly New Player Report',
  TOTAL_PLAYERS: 'Total Players',
  TOTAL_NEW_PLAYERS: 'Total New Players',
  TOTAL_NEW_USERS: 'Total New Users',

  // Reporting Dashboard
  LOADING_REPORTING_DASHBOARD: 'Loading Reporting Dashboard',
  REPORTING_DASHBOARD_NAME: 'Automated Weekly Dashboard',
};

export default ENG;


