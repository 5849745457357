const IDN = {
  //COMMON
  HOME: 'Beranda',
  BROADCAST_SCHEDULE: 'Jadwal Pertandingan',
  WATCH_THE_MATCH: 'Tonton Pertandingan',
  PREDICT_NOW: 'Prediksi Sekarang',
  PREDICT_NOW_BTN: 'Prediksi Sekarang',
  LEADERBOARD: 'Papan Peringkat',
  HOW_TO_PLAY: 'Cara Main',
  NEWS: 'Berita',
  CONTACT: 'Kontak',
  LOGIN: 'Masuk',
  CLOSE: 'Tutup',
  FIGHT_WEEK: 'PEKAN PERTANDINGAN ',
  LIVE_NOW: ' LIVE',
  CONTINUE: 'Lanjutkan',
  USER_NAME: 'Nama Pengguna',
  HOURS: ' jam',
  MIN: ' menit',
  DAYS: ' hari',
  MONTHS: ' bulan',
  YEARS: ' tahun',
  SIGN_OUT: 'KELUAR',
  USER_DOES_NOT_EXIST: "Pengguna tidak ada",
  TOTAL: 'Total',
  //HOME
  HOME_TITLE: 'PREDIKSI PERTANDINGAN <br> DAN RAIH KEMENANGAN BESAR',
  HOME_TAGLINE:
    'Tebak hasil pertarungan dan raih kesempatan <br> untuk memenangkan hadiah utama setiap minggu.',
  HOME_BUTTON: 'Prediksi Sekarang',
  HOME_BUTTON_PREDICT: 'PREDIKSI',
  HOME_BUTTON_NOW: 'SEKARANG',
  HOME_READ_BEFORE_PREDICTING: 'Baca Sebelum Memprediksi',
  HOME_HOW_TO_PLAY: 'Cara Main',
  HOME_READ_BEFORE_PREDICTING_TEXT: "Sebelum memasukan prediksi Anda, pastikan Anda mengetahui sistem poin SuperChallenge Fight Predictor:<br><br>" +
  "<ol>" +
  "<li>Memprediksi dengan benar hasil pertarungan akan memberi Anda 3 poin.</li><br>" + 
  "<li>Menebak metode kemenangan dengan benar - apakah itu KO/TKO, Keputusan, atau Submission - akan memberi Anda total 12 poin (4 poin dikalikan dengan poin dasar hasil pertarungan).</li><br>" + 
  "<li>Jika Anda menebak jumlah ronde pertarungan dengan tepat, Anda akan mendapatkan total 9 poin (3 poin dikalikan dengan poin dasar hasil pertarungan).</li><br>" + 
  "<li>Jika Anda berhasil memprediksi ketiga elemen - hasil, metode, dan jumlah ronde - Anda akan mendapatkan bonus tambahan 2 poin. Oleh karena itu, jika semua prediksi Anda untuk sebuah pertarungan benar, total poin Anda untuk pertarungan tersebut adalah 24 poin dari prediksi ditambah 2 poin bonus, sehingga totalnya menjadi 26 poin.</li><br>" + 
  "</ol>" +
  "Jangan lupa, ada pertarungan unggulan setiap minggunya di mana kamu bisa melipatempatkan poin ganda! Jika kamu benar dalam semua aspek dari pertarungan unggulan ini, poinmu akan dikalikan 4 dari 24 menjadi 96, dan kemudian kamu akan mendapatkan tambahan 2 poin untuk memprediksi semua aspek secara akurat - hasil, metode, dan ronde - sehingga total poin untuk pertarungan unggulan adalah 98 poin.<br><br>" + 
  "Penting untuk diketahui, jika Anda salah memprediksi hasil pemenang pertarungan, Anda tidak akan mendapatkan poin apa pun untuk pertarungan tersebut, meskipun prediksi Anda tentang metode dan jumlah ronde benar.<br><br>" +
  "PERHATIAN: Jadwal pertarungan di situs web ini dapat berubah sewaktu-waktu sesuai dengan jadwal resmi yang ditetapkan oleh pihak penyelenggara MMA seperti UFC, Bellator, Cage Warriors, dan lain-lain. Jika ada perubahan petarung setelah Anda membuat prediksi, maka prediksi Anda akan dibatalkan secara otomatis. Anda harus memasukkan prediksi baru untuk pertarungan yang diperbarui. Anda dapat mengubah prediksi secara manual sampai batas waktu submission. Setiap individu bertanggung jawab untuk memantau pembaruan pertandingan dan mengelola prediksi mereka.",
  REWARD: 'Hadiah',
  //BROADCAST
  BROADCAST_NOTES: 'Harap diperhatikan bahwa Prelims berada di Tab terpisah',
  BROADCAST_NOTES_MORE_EVENTS: 'Harap pilih Acara terlebih dahulu.',
  BROADCAST_MAIN_CARD: 'Main Card',
  BROADCAST_PRELIMS: 'Prelims',
  BROADCAST_EARLY: 'Prelims Awal',
  BROADCAST_MAIN_EVENT: 'Acara Utama',
  PLAYER_FAV: 'Favorit Pemain MFP',
  BROADCAST_POINTS_INFO: 'Info Poin',
  BROADCAST_FIGHTER_RECORD: 'Data Petarung',
  BROADCAST_POINTS_ALLOCATION: 'Penetapan Poin untuk Acara Ini',
  BROADCAST_POINTS_ALLOCATION_NO_EVENT: 'Alokasi poin',
  BROADCAST_CORRECT_OUTCOME: 'Keluaran yang Benar (Menang, Seri): ',
  BROADCAST_CORRECT_METHOD: 'Metode yang Benar (KO/TKO dll.): ',
  BROADCAST_CORRECT_METHOD_SUBMISSION: 'Metode yang Benar Pengajuan: ',
  BROADCAST_CORRECT_METHOD_DECISION: 'Correct Method Keputusan: ',
  BROADCAST_BONUS: 'Bonus atas Hasil yang Benar',
  BROADCAST_BONUS_POINTS: 'Poin',
  BROADCAST_BONUS_ALL: 'Bonus atas Hasil, Metode & Putaran yang Benar:',
  BROADCAST_CORRECT_ROUND: 'Jumlah Putaran yang Benar: ',
  BROADCAST_MULTIPLIER_MAIN: 'Pengali Poin Main Card: ',
  BROADCAST_MULTIPLIER_PRELIMS: 'Pengali Poin Prelims: ',
  BROADCAST_MULTIPLIER_FEATURED: 'Pengali Poin Featured: ',
  BROADCAST_404: 'Belum ada prediksi',
  BROADCAST_COUNTDOWN: 'Permintaan prediksi akan dibuka pada ',
  BROADCAST_CLOSE_ON: 'Permintaan prediksi telah ditutup pada ',
  BROADCAST_AVAILABLE_SOON:
    'Acara Telah Berakhir: Hasil Papan Peringkat akan segera tersedia.',
  BROADCAST_END:
    'Acara Telah Berakhir: Hasil kemenangan dapat dilihat di Papan Peringkat.',
  BROADCAST_HOW: 'Bagaimana?',
  BROADCAST_WHICH_ROUND: 'Ronde Berapa?',
  BROADCAST_CANCELLED: 'Pertandingan dibatalkan!',
  BROADCAST_HOW_2: 'Hasil:',
  BROADCAST_WHICH_ROUND_2: 'Ronde:',
  BROADCAST_LOGIN: 'Login untuk membuat prediksi',
  BROADCAST_EVENT_404: 'Saat ini tidak ada Pertandingan yang berlangsung.',
  BROADCAST_STAY_TUNED: 'Pantau terus!',
  FIGHTER_PROFILE: 'Profile Petarung',
  FIGHTER_SEE_MORE: 'Lihat lebih banyak detail tentang petarung ini di sini',
  MFP_FAVOURITE: 'Favorit Pemain MFP',
  SPONSORED: 'Pertarungan Spesial',
  PREDICTIONS_FROM: 'Prediksi dari {x} pengguna',
  PREDICTIONS_FROM_ONE: 'Prediksi dari 1 pengguna',
  //RECAP
  RECAP_MON_RANK: 'Montlhy Rank',
  RECAP_MON_POINT: 'Monthly Points',
  RECAP_WEEK_RANK: 'Weekly Rank',
  RECAP_WEEK_POINT: 'Weekly Points',
  //AUTH
  AUTH_LOGIN: 'Masuk ke Mola - Fight Predictor',
  AUTH_EMAIL: 'Email',
  AUTH_PASSWORD: 'Kata Sandi',
  AUTH_PASSWORD_HINT: 'Minimal 8 karakter',
  AUTH_ALREADY_HAVE_ACCOUNT: 'Sudah punya akun? Masuk dengan kata sandi.',
  AUTH_BUTTON: 'Masuk / Daftar',
  AUTH_CONTINUE_WITH_GOOGLE: 'Lanjutkan dengan Google',
  AUTH_CONFIRM_EMAIL: 'Silakan cek email Anda!!',
  AUTH_CONFIRM_EMAIL_2: 'Tautan konfirmasi sudah dikirim ke email Anda!',
  AUTH_EMAIL_VALIDATION: 'Email harus valid',
  AUTH_ONE_STEP_AHEAD: 'Satu Langkah Lebih Maju',
  AUTH_INVALID_EMAIL: 'Tautan email tidak valid!',
  AUTH_WELCOME_BACK:
    '"Selamat Datang Kembali! Mengalihkan Anda ke halaman beranda dalam beberapa detik ......"',
  //LEADERBOARD
  LEADERBOARD_MONTHLY_LEADERBOARD: 'PERINGKAT BULANAN',
  LEADERBOARD_WEEKLY_LEADERBOARD: 'PERINGKAT MINGGUAN',
  LEADERBOARD_WEEKLY_LEADERBOARD_FUN: 'PERINGKAT MINGGUAN FUN',
  LEADERBOARD_MONTHLY_REWARD: 'PERINGKAT REWARD BULANAN',
  LEADERBOARD_WEEKLY_REWARD: 'PERINGKAT REWARD MINGGUAN',
  LEADERBOARD_MONTHLY: 'Bulanan',
  LEADERBOARD_WEEKLY: 'Mingguan',
  LEADERBOARD_MONTHLY_FUN: 'Bulanan Fun',
  LEADERBOARD_WEEKLY_FUN: 'Mingguan Fun',
  LEADERBOARD_REWARD_MONTHLY: 'Bulanan Reward',
  LEADERBOARD_REWARD_WEEKLY: 'Mingguan Reward',
  LEADERBOARD_RANK: 'Peringkat',
  LEADERBOARD_TOTAL_POINTS: 'Jumlah Poin',
  LEADERBOARD_TOTAL_MOLA_POINTS: 'Jumlah Poin Mola',
  LEADERBOARD_POINTS: 'Poin',
  LEADERBOARD_CORRECT_OUTCOME: 'Hasil Benar',
  LEADERBOARD_CORRECT_METHOD: 'Metode Benar',
  LEADERBOARD_CORRECT_ROUNDS: 'Ronde Benar',
  LIVE_EVENT_LEADERBOARD: 'Papan Peringkat Acara Langsung',
  LEADERBOARD_DETAILS_FOR: 'Rincian untuk',
  LEADERBOARD_MY_RANKING: 'Peringkat Saya',
  LEADERBOARD_NO_RANKING: 'Tidak ada peringkat',
  //PREDICTION
  PREDICTION: ' Prediksi',
  PREDICTION_GAME: 'Permainan',
  PREDICTION_GAME_DATE: 'Tanggal',
  PREDICTION_OUTCOME: 'Hasil',
  PREDICTION_METHOD: 'Metode',
  PREDICTION_ROUNDS: 'Ronde',
  PREDICTION_SUBMISSION: 'Pengajuan (Submission)',
  PREDICTION_DECISION: 'Keputusan',
  PREDICTION_LOSES: 'Kalah',
  PREDICTION_WIN: 'Menang',
  PREDICTION_WINNER: 'Pemenang',
  PREDICTION_DRAW: 'Seri',
  YOUR_PREDICTION: 'Prediksi Anda',
  YOUR_PREDICTION_1: 'Prediksi',
  YOUR_PREDICTION_2: 'Anda',
  COPY_PREDICTION: 'Salin atau unduh prediksi Anda',
  SHARE_PREDICTION: 'Bagikan di media sosial',
  WHICH_ROUND: 'Ronde Berapa?',
  SUBMIT_PREDICTION: 'Masukkan Prediksi',
  UPDATE_PREDIDCTION: 'Perbaharui Prediksi',
  CHANGE_PREDIDCTION: 'Ubah Prediksi',
  INFO_PREDICTION: 'Prediksimu telah disimpan.',
  PREDICTION_CLOSE_IN: 'Prediksi akan ditutup pada ',
  IN_X_DAYS: 'dalam {x} hari',
  //CONTACT
  CONTACT_US: 'Hubungi Kami',
  CONTACT_MESSAGE:
    'Jangan ragu-ragu untuk menghubungi kami jika Anda memerlukan informasi lebih lanjut atau memiliki masalah lain.',
  CONTACT_BUTTON: 'Kirim kami beberapa email',
  //NEWS
  NEWS_MFP: 'BERITA SUPERCHALLENGE',
  NEWS_VIEWS: ' Melihat',
  NEWS_VIEW: ' Melihat',
  NEWS_SHARE: 'Bagikan',
  NEWS_DAYS_AGO: ' Days Ago',
  NEWS_SHARE_VIA: 'Bagikan melalui',
  // FAQ
  FAQ: 'FAQ',
  FAQ_LONG: 'PERTANYAAN YANG SERING DIAJUKAN',
  FAQ_TITLE_01: '1. Tentang SuperChallenge Fight Predictor?',
  FAQ_TITLE_02: '2. Tentang Langganan Mola?',
  FAQ_TITLE_03: '3. Katalog Mola dan Penukaran Hadiah?',
  FAQ_TITLE_04: '4. Kontak dan Dukungan',
  //MY ACCOUNT HOME
  MY_ACCOUNT: 'Profil & Poin',
  MY_ACCOUNT_WELCOME: 'Selamat Datang',
  MY_ACCOUNT_MOLA_POINTS: 'Poin',
  MY_ACCOUNT_POINTS_WON: 'Poin Mingguan',
  MY_ACCOUNT_SWAP: 'Permintaan Penukaran',
  MY_ACCOUNT_PROFILE: 'Profil Saya',
  //PROFILE
  MY_PROFILE: 'Profil Saya',
  MY_PROFILE_ACCOUNT_VERIFICATION: 'Verifikasi Akun',
  MY_PROFILE_ACCOUNT_EMAIL: 'Email Akun',
  MY_PROFILE_PASSWORD: 'Kata Sandi',
  MY_PROFILE_ACCOUNT_INFORMATION: 'Informasi Akun',
  MY_PROFILE_ACCOUNT_TYPE: 'Tipe Akun',
  MY_PROFILE_ACCOUNT_STATUS: 'Status Akun',
  MY_PROFILE_MOLA_SUBSCRIPTION: 'Status Langganan Mola',
  MY_PROFILE_ACTIVE: 'Aktif',
  MY_PROFILE_NOT_MOLA_SUBSCRIBER: 'Bukan Pelanggan Mola',
  MY_PROFILE_FREE: 'Gratis',
  MY_PROFILE_PERSONAL_INFORMATION: 'Informasi Pribadi',
  MY_PROFILE_DISPLAY_NAME: 'Nama Tampilan',
  MY_PROFILE_ABOUT_ME: 'Tentang Saya',
  MY_PROFILE_FULL_LEGAL_NAME: 'Nama Lengkap',
  MY_PROFILE_DOB: 'Tanggal Lahir',
  MY_PROFILE_GENDER: 'Jenis Kelamin',
  MY_PROFILE_ADDRESS_DETAILS: 'Detail Alamat',
  MY_PROFILE_LANGUAGE: 'Bahasa',
  MY_PROFILE_CURRENT_VERSION: 'Versi Saat Ini',
  MY_PROFILE_TANDC: 'Syarat & Ketentuan',
  MY_PROFILE_PRIVACY_POLICY: 'Kebijakan Privasi',
  MY_PROFILE_UPGRADE_NOW: `Langganan Sekarang`,
  MY_PROFILE_UPGRADE_NOW_MOBILE: `Langganan <br /> Sekarang`,
  MY_PROFILE_SUBSCRIBE_NOW: 'Berlangganan Sekarang',
  MY_PROFILE_SUBSCRIBE_NOW_MOBILE: 'Berlangganan <br /> Sekarang',
  MY_PROFILE_SIGN_OUT: 'Keluar dari Akun Anda',
  MY_PROFILE_BUTTON_CHANGE: 'Ubah',
  MY_PROFILE_ABOUT: '',
  MY_PROFILE_DOB_TEXT: 'Masukkan tanggal lahir anda',
  MY_PROFILE_GENDER_NOT_SET: 'BELUM DITENTUKAN',
  MY_PROFILE_GENDER_M: 'LAKI - LAKI',
  MY_PROFILE_GENDER_F: 'PEREMPUAN',
  MY_PROFILE_GENDER_O: 'LAINNYA',
  MY_PROFILE_PASSWORD_SET: 'Tolong, atur password anda',
  MY_PROFILE_NEW_EMAIL: 'Tolong atur email anda',
  MY_PROFILE_CHECK_EMAIL: '',
  MY_PROFILE_EMAIL_INSTRUCTIONS: '',
  MY_PROFILE_BUT_CHANGE_EMAIL: 'Ubah Email',
  MY_PROFILE_ACC_NOT_VERIFIED: '',
  MY_PROFILE_ACC_VERIFIED: '',
  MY_PROFILE_ACC_BTN_VERIFY: '',
  MY_PROFILE_ACC_VERIFY: '',
  MY_PROFILE_PASSWORD_SUGGESTION: 'Saran Kata Sandi yang Kuat',
   MY_PROFILE_PASSWORD_SUGGESTION_TEXT: `
     &bull; Minimal 8 karakter—semakin banyak karakter, semakin baik.<br />
     &bull; Campuran huruf besar dan kecil.<br />
     &bull; Campuran huruf dan angka.<br />
     &bull; Penyertaan setidaknya satu karakter khusus, misalnya, ! @ # ? ]
   `,
  ACC_EMAIL_NOT_SET: 'Your email adress has not been set',
  ACC_EMAIL_ASS:
    'We have assigned you a temporary email address that is used for auhtentication together with your connected wallet.',
  // SETTINGS UPDATED
  SETTINGS_UPDATED_DISPLAYNAME: 'Nama Tampilan Diperbarui',
  SETTINGS_UPDATED_ABOUTME: 'Tentang Saya Diperbarui',
  SETTINGS_UPDATED_FULLNAME: 'Nama Lengkap Diperbarui',
  SETTINGS_UPDATED_DOB: 'Tanggal Lahir Diperbarui',
  SETTINGS_UPDATED_GENDER: 'Gender Diperbarui',
  SETTINGS_UPDATED_ADDRESS: 'Alamat Diperbarui',
  SETTINGS_UPDATED_LANGUAGE: 'Bahasa diperbarui',
  SETTINGS_UPDATED_PHOTO: 'Foto Profil Diperbarui',
  //POINTS
  POINTS_NON_MOLA:
    'Karena anda tidak berlangganan, poin yang anda menangkan tidak dapat ditukar. untuk dapat menukar poin yang anda menangkan, tingkatkan layanan berbayar anda!',
  POINTS_FOR: 'Poin dimenangkan pada acara terakhir',
  POINTS_404:
    'Tidak ada hasil atau poin yang dimenangkan untuk pertarungan acara ini',
  POINTS_MORE: 'Muat Lebih Banyak <br/> dari Acara Masa Lalu',
  POINTS_HISTORY: 'Riwayat Poin Dimenangkan',
  POINTS_HISTORY_404: 'Riwayat Poin tidak ditemukan',
  //ADMIN
  ADMIN_DASHBOARD: "KPI Dasbor Admin",
  REPORTING_DASHBOARD: 'Dasbor Pelaporan',
  GAME_MANAGEMENT: "Atur Permainan",
  ADMIN_MANAGEMENT: "Tugas Admin",
  EVENTS_AND_GAMES: "Acara & Permainan",
  GAME_RESULTS: "Hasil Permainan",
  CREATE_EVENT: "Buat Acara",
  NEWS_MANAGEMENT: "Atur Berita",
  HOME_MANAGEMENT: "Atur Beranda",
  WATCH_MANAGEMENT: "Atur Tonton Pertandingan",
  REWARD_MANAGEMENT: "Manajemen Hadiah",
  WATCH_MATCH_FREE: 'Tonton pertandingannya secara gratis',
  WATCH_ON_MOLA: 'Tonton di Mola',
  POINTS_AND_TOKEN_MANAGEMENT: "Manajemen Poin & Token",
  POINTS_MANAGEMENT: "Manajemen Poin",
  SWAP_REQUEST: "Permintaan Tukar",
  MANAGE_EVENTS_AND_GAMES: "Kelola Acara dan Permainan",
  PROCESS_GAME_RESULTS: "Proses Hasil Permainan",
  CREATE_NEW_PREDICTION_GAME: "Buat Permainan Prediksi Baru untuk Acara dan Pertarungan",
  MANAGE_NEWS: "Kelola Berita",
  // DASHBOARD
  ADMIN_TOTAL_SIGN_UPS: "Total Pendaftaran",
  ADMIN_TOTAL_SIGN_UPS_TODAY: "Total Pendaftaran Hari Ini",
  ADMIN_TOTAL_SIGN_UPS_THIS_MONTH: "Total Pendaftaran Bulan Ini",
  ADMIN_TOTAL_MOLA_SUBSCRIBERS: "Total Pelanggan Mola",
  ADMIN_TOTAL_PREDICTIONS: "Total Prediksi",
  ADMIN_TOTAL_POINTS_WON: "Total Poin Dimenangkan",
  DATE: 'Tanggal',
  EVENT: 'Acara',
  FIGHT: 'Pertandingan',
  ROWS_PER_PAGE: 'Baris per halaman', 
  ROWS_PER_PAGE_OF: 'dari',
  ROWS_PER_PAGE_ALL: 'Semua',
  //FOOTER
  FOOTER_TITLE: 'PREDIKSI PERTANDINGAN DAN RAIH KEMENANGAN BESAR',
  FOOTER_TITLE_NEW: 'BERLANGGANAN MOLA',
  MOLA_FIGHT_CLUB: 'SuperChallenge Fight Predictor',
  ABOUT: 'Tentang Mola Digital',
  SOCIAL: 'Jejaring Sosial',
  //Register
  REGISTER_WELCOME_TO_MFP: 'Selamat datang di Mola - Fight Predictor',
  REGISTER_LOGGED: 'Anda sudah masuk!',
  REGISTER_CONTINUE: 'Lanjutkan untuk menyelesaikan pembuatan akun Anda',
  REGISTER_COMPLETE: 'Lengkapi Akun Anda',
  REGISTER_IN_ORDER:
    'Untuk menyelesaikan pengaturan akun Anda, kami membutuhkan informasi sedikit lebih dari Anda',
  REGISTER_FULL_NAME: 'Nama Lengkap',
  REGISTER_PASSWORD: 'Kaat Sandi',
  REGISTER_SUBMIT: 'Kirim',
  REGISTER_WELCOME: 'Selamat Datang',
  REGISTER_WELCOME_BACK: 'Selamat Datang Kembali',
  REGISTER_THANKS:
    'Terima kasih telah bergabung bersama kami Anda akan dialihkan ke halaman beranda....',
  REGISTER_REDIRECT:
    'Mengalihkan anda ke halaman beranda dalam beberapa detik...',
  //Authorise
  LOADING: 'Memuat...',
  LOADING_LEADERBOARD_DATA: 'Memuat Data Papan Peringkat...',
  ERROR: 'Error...',
  PLEASE_WAIT: 'Mohon tunggu beberapa saat...',
  BACK_TO_HOME: 'Kembali ke Beranda',
  LOCAL_TIME: 'Waktu Lokal',
  LOCAL: 'Lokal',
  LEADERBOARD_TITLE: 'PERINGKAT',
  AUTH_MESSAGE: `Il codice di attivazione non è corretto.
  Questo è successo poiché il codice non è
  stato scritto correttamente, è scaduto o è
  stato già usato.`,
  //New
  MOLA_CATALOG: 'Tukar Poin',
  TO_MOLA_CATALOG_ADMIN: 'Ke Mola Katalog Admin',
  ALL_FIGHTS: 'Semua Pertandingan',
  MEMBER_SINCE: 'Anggota Sejak',
  PROFILE_SCORE: 'Skor Profil',
  VERIFIED: 'Terverifikasi',
  NOT_VERIFIED: 'Tidak Terverifikasi',
  VERIFY: 'Verifikasi',
  IMA: 'Saya ',
  STARTER: 'Pemula',
  ACTIVE: 'Aktif',
  INACTIVE: 'Tidak Aktif',
  SUSPENDED: 'Ditangguhkan',
  HOW_TO_PLAY_TEXT: "<ol>" +
  "<li>Klik tombol “Masuk” dan klik 'Daftar' untuk mendaftarkan diri Anda.</li><br>" + 
  "<li>Jika Anda pelanggan aktif Mola, pastikan Anda menggunakan email yang sama untuk registrasi dengan email yang digunakan untuk berlangganan Mola. Jika Anda bukan pelanggan aktif Mola, Anda dapat langsung memasukkan alamat email Anda.</li><br>" + 
  "<li>Lengkapi detail diri Anda pada halaman 'Profil Saya'.</li><br>" + 
  "<li>Masukkan prediksi Anda untuk pertandingan mendatang. Mulai dari petarung yang menang, cara menang dan ronde berakhirnya pertandingan. Pastikan Anda memprediksi semua pertandingan yang tersedia untuk mendapatkan poin maksimal.</li><br>" + 
  "<li>Jika ingin mengubah prediksi, Anda dapat melakukannya langsung pada halaman Prediksi dan klik 'Ubah Prediksi'.</li><br>" + 
  "<li>Saksikan pertandingannya di Mola dan ketahui hasil pertandingannya.</li><br>" + 
  "<li>Ketika pertandingan berakhir, Anda dapat melihat “Poin Mingguan” Anda pada halaman profil dan cek ranking Anda di halaman 'Peringkat'.</li><br>" + 
  "<li>SuperChallenge Fight Predictor dapat diakses oleh siapapun, namun pastikan Anda pelanggan aktif Mola jika Anda ingin mendapatkan 'Poin Mola', yang nantinya dapat Anda tukarkan dengan berbagai hadiah eksklusif yang tersedia di Mola Catalog.</li><br>" +
  "</ol>",
  REDEEM_NOW: 'Tukarkan!',
  LOAD_MORE: 'Muat lebih banyak',
  PREDICTION_ALERT: 'Mohon pastikan kolom <strong>prediksi</strong> diatas terisi semua',
  SECONDS_AGO: ' detik yang lalu',
  MINUTES_AGO: ' menit yang lalu',
  HOURS_AGO: ' jam yang lalu',
  DAYS_AGO: ' hari yang lalu',
  MONTHS_AGO: ' bulan yang lalu',
  YEARS_AGO: ' tahun yang lalu',
  BIRTHDAY: 'Tanggal Lahir',
  CITY: 'Kota',
  POSTAL_CODE: 'Kode Pos',
  // Mola Points and Weekly Points Won - Quick Guide
  QUICK_GUIDE_TITLE: 'Poin Mola dan Poin Mingguan - Panduan Singkat',
  QUICK_GUIDE_SECTION_01: 'Poin Mingguan',
  QUICK_GUIDE_SECTION_01_TEXT: 'Menangkan poin Mingguan untuk setiap prediksi yang tepat di situs kami. Poin ini sebagian besar hanya untuk hiburan, meskipun ini dapat memberi Anda keunggulan kompetitif dalam acara-acara khusus kami di masa mendatang. Semua pengguna dapat memperoleh Poin Mingguan.',
  QUICK_GUIDE_SECTION_02: 'Poin Mola',
  QUICK_GUIDE_SECTION_02_TEXT: 'Eksklusif untuk pengguna dengan langganan Mola yang aktif. Selain Poin Mingguan yang diperoleh, jumlah yang sama akan secara otomatis dikonversi menjadi Poin Mola dan akan ditambahkan ke dalam total Poin Mola Anda. Dapatkan poin-poin ini, selain Poin Mingguan yang Diperoleh, dengan cara memenangkan prediksi Anda. Pastikan alamat email yang Anda gunakan untuk memprediksi sama dengan email langganan Mola Anda. Ingat, langganan Mola Anda harus aktif sebelum Anda memasukkan prediksi.',
  QUICK_GUIDE_SECTION_03: 'Menukarkan Poin',
  QUICK_GUIDE_SECTION_03_TEXT: 'Tukarkan Poin Mola dengan hadiah dari Katalog Mola. Buka Katalog Mola atau klik "Tukarkan" di menu "Poin Mola" untuk menukarkan poin. <strong>Hanya Poin Mola yang dapat digunakan untuk penukaran.</strong>',
  DATA_404: 'Data tidak ditemukan',
  LIVE_LEADERBOARD: 'Peringkat Pertandingan Langsung',
  NEXT_FIGHT_UP: 'Pertarungan Selanjutnya:',
  EVENT_END: 'Pertandingan ini sudah berakhir',
  NO_RESULT: 'Belum ada hasil.<br>Pertandingan pertama masih berlangsung atau belum selesai.',
  NICKNAME: 'Nama Panggilan: ',
  AGE: 'Umur: ',
  DOB: 'Tanggal Lahir: ',
  GENDER: 'Jenis Kelamin: ',
  WINS: 'Menang',
  LOSSES: 'Kalah',
  DRAWS: 'Seri',
  MINUTES: ' menit',
  SECONDS: ' detik',
  IN_HOURS_AND_MIN: '{x} jam dan {y} menit',
  SORT_BY_DATE: 'Urutkan Berdasarkan Tanggal',

  EMPTY: `{variable-name} tidak boleh kosong`,
  MINIMUM_LENGTH: `Panjang minimum {variable-name} adalah {number}`,

  CHANGE_YOUR_PASSWORD: 'Ubah Kata Sandi Anda',
  NEW_PASSWORD: 'Kata Sandi Baru',
  CURRENT_PASSWORD: 'Kata Sandi Saat Ini',
  SET_NEW_PASSWORD: 'Atur Kata Sandi Baru',
  CLICK_TO_SELECT_A_PHOTO: 'Klik untuk memilih foto',
  CHANGE_PHOTO: 'Ubah Foto',
  REMOVE_PHOTO: 'Hapus Foto',
  SAVE: 'Simpan',
  SET_PICTURE: 'Atur Gambar',
  TAP_TO_SELECT_PHOTO: 'Ketuk di sini untuk memilih foto <br>dari galeri Anda',
  AGREE: 'Dengan Masuk, Anda telah membaca dan menyetujui',
  AND: 'dan',
  DOWNLOAD_PLAYSTORE: 'Unduh Mola di Playstore',
  DOWNLOAD_APPSTORE: 'Unduh Mola di Appstore',
  INSTAGRAM: 'Instagram @mola.sport',
  TIKTOK: 'Tiktok @mola.sport',

  // Duelling
  DUEL_PROVE: `
       Buktikan bahwa Anda lebih baik dari mereka! Jika Anda mendapatkan poin lebih tinggi pada prediksi Anda daripada lawan Anda, Anda akan mendapatkan semua poin Mola mereka dari prediksi Minggu ini
  `,
  DUEL_TITLE: 'DUEL DENGAN TEMAN',
  INVITATION_SENT: 'UNDANGAN DIKIRIM',
  HOW_IT_WORKS: 'Bagaimana cara kerjanya?',
  WHOS_YOUR_OPPONENT: `SIAPA LAWAN ANDA?`,
  SEARCH_OPPONENT: 'Cari nama pengguna lawan',
  SEARCH_OPPONENT_2: 'Ketik username lawan duel Anda',
  SEND_DUEL_INVITATION: 'KIRIM UNDANGAN DUEL',
  DUEL_CONFIRM: 'Silakan periksa Notifikasi Anda Nanti!',
  DUEL_CAPTION: 'Undangan duel Anda sudah dikirimkan ke lawan Anda! Setelah lawan Anda menerima atau menolak, itu akan mengirimkan pemberitahuan Anda!',
  DUEL_USER_ALREADY_INVITED: ' telah diundang ke duel lain',
  FROM: 'Dari',
  TO: 'Kepada',
  DUEL_BUTTON: 'Duel Dengan Temanmu',
  SHARE_BUTTON: 'Bagikan Prediksi Anda',
  DUEL_SHARE_INFO: 'Pastikan kamu sudah submit prediksi 5 pertandingan di atas untuk fitur “Bagikan Prediksi Anda” dan “Duel Dengan Temanmu”',
  DUEL_WIN: 'Menang dalam Duel Prediksi ',
  DUEL_LOSE: 'Kalah dalam Duel Prediksi ',
  DUEL_DRAW: 'Duel Prediksi Imbang ',
  DUEL_WIN_WITH: 'Menang dalam Duel Prediksi dengan ',
  DUEL_LOSE_WITH: 'Kalah dalam Duel Prediksi dengan',
  DUEL_DRAW_WITH: 'Duel Prediksi Imbang dengan ',
  OPPONENT_NOT_MOLA_SUBSCRIPTION: `Lawan Bukan Pelanggan Mola`,
  DUEL_USER_MUST_BE_MOLA_SUBSCRIPTION: `Anda bukan Pelanggan Mola`,
  ACCEPT: 'TERIMA',
  REJECT: 'TOLAK',
  ACCEPT_DUEL: 'Undangan diterima',
  REJECT_DUEL: 'Undangan ditolak',
  ACCEPT_DUEL_SENDER: 'Undangan anda diterima',
  REJECT_DUEL_SENDER: 'Undangan anda ditolak',
  // Refer your friends
  MY_REFERRAL_EARNINGS: 'PENGHASILAN REFERRAL SAYA',
  REFERAL_CODE: 'Kode Undang (opsional)',
  INVALID_CODE: 'Kode Undang Tidak Valid!',
  CHECK_CODE: 'Silakan periksa kode Anda atau lanjutkan tanpa.',
  CONGRATULATIONS: 'Berhasil!',
  FIRST_PREDICTION_TEXT1: 'Anda telah membuat prediksi pertama Anda.',
  FIRST_PREDICTION_TEXT2: 'Anda dan teman Anda yang mengundang Anda ke MFP baru saja menerima 5 Poin Mola masing-masing.',
  CONTINUE_PREDICTING: 'Lanjutkan Memprediksi',
  COPY_TO_CLIPBOARD: 'Menyalin ke clipboard',
  REFER_YOUR_FRIENDS: 'UNDANG TEMAN ANDA',
  REFER_YOUR_FRIENDS_1: 'UNDANG',
  REFER_YOUR_FRIENDS_2: 'TEMAN ANDA',
  REFER_NOW: 'Undang Sekarang',
  INVITE_TEXT1: 'Undang teman Anda ke MFP!',
  INVITE_TEXT2: 'Anda dan teman Anda akan mendapatkan <span style="color:#f8df8e">5 POIN</span>',
  SHARE_CODE: 'Bagikan kode undang Anda <strong>"{x}"</strong> untuk memberi teman Anda 5 Poin Mola. Saat mereka mengirimkan prediksi pertamanya, Anda juga mendapatkan 5 Poin Mola!',
  COPY_CODE: 'Hai! Ayo berkompetisi di Mola Fight Predictor! Anda dan saya akan mendapatkan 5 Poin Mola ketika Anda memasukkan kode referensi saya <strong style="color: #2196F3">"{x}"</strong> dan mengirimkan prediksi pertama Anda. Daftar <a href="https://molafightclub.com">di sini<a/>',
  COPY_CODE_PLAIN: 'Hai! Ayo berkompetisi di Mola Fight Predictor! Anda dan saya akan mendapatkan 5 Poin Mola ketika Anda memasukkan kode referensi saya "{x}" dan mengirimkan prediksi pertama Anda. Daftar di https://molafightclub.com',
  YOU_ALREADY_DUEL: 'Anda sedang berduel dengan:',
  SUCCESS_COPY_IMAGE: 'Gambar berhasil disalin!',
  NOTIFICATIONS: 'NOTIFIKASI',
  INBOX: 'Pesan Masuk',
  OUTBOX: 'Pesan Keluar',
  SENT: 'Terkirim',
  FALLBACK_SHARE: 'Fitur berbagi tidak didukung di browser ini, silakan gunakan opsi unduh',
  SHOULD_BE_MOLA_SUBS: 'Anda harus berlangganan Mola untuk bisa memainkan fitur ini. Berlangganan sekarang dan mainkan fitur ini bersama teman anda!',
  SUBSCRIBE_HERE: 'BERLANGGANAN DI SINI',
  INVITE_TO_MOLA: `
    Langganan Mola sekarang untuk bisa Duel di Mola Fight Predictor!
    <br/> Klik <a href="https://mola.tv/" target="__blank">link</a> ini untuk langganan Mola.
  `,
  INVITE_TO_MOLA_PLAIN: `Langganan Mola sekarang untuk bisa Duel di Mola Fight Predictor! Pergi ke https://mola.tv/ untuk langganan Mola.`,
  HOMEPAGE_MANAGEMENT: "Manajemen Beranda",
  LIVE_BUTTON_LINK: "Tautan Tombol Langsung",
  WATCH_THE_MATCH_LINK: "Tautan Tonton Pertandingan",
  HOME_CONFIG_TITLE: "Judul Beranda",
  HOME_CONFIG_TAGLINE: "Tagline Beranda",
  CAROUSEL_IMAGES_AND_LINKS: "Gambar dan Tautan Karusel",
  CAROUSEL_INTERVAL: "Interval Karusel",
  SLIDE_TIME_IN_SECONDS: "Waktu slide dalam detik.",
  HOME_BANNER_DESKTOP: "Banner Beranda Desktop",
  HOME_BANNER_DESKTOP_LINK: "Tautan Banner Beranda Desktop",
  URL_WHEN_BANNER_IS_CLICKED: "URL saat banner diklik",
  HOME_BANNER_MOBILE: "Banner Beranda Mobile",
  HOME_BANNER_MOBILE_LINK: "Tautan Banner Beranda Mobile",
  PREVIEW: "Pratinjau",
  SAVE_CONFIGURATION: "Simpan Konfigurasi",

  TITLE_AT_HOME_BANNER: "Judul pada Banner Beranda",
  ENTER_TITLE_FOR_HOME_BANNER: "Petunjuk: Masukkan Judul untuk banner beranda",
  SET_TITLE_TO_BLANK: "Kosongkan Judul",
  SET_TITLE_TO_BLANK_WITHOUT_DELETE_ANYTHING: "Kosongkan judul tanpa menghapus apapun",
  TAGLINE_AT_HOME_BANNER: "Tagline pada Banner Beranda",
  ENTER_TAGLINE_FOR_HOME_BANNER: "Petunjuk: Masukkan tagline untuk banner beranda",
  SET_TAGLINE_TO_BLANK: "Kosongkan Tagline",
  SET_TAGLINE_TO_BLANK_WITHOUT_DELETE_ANYTHING: "Kosongkan tagline tanpa menghapus apapun",

  WATCH_LIVE_BUTTON_LINK: "Tautan Tombol Tonton Langsung",
  PAGE_TITLE: "Judul Halaman",
  TITLE_AT_WATCH_THE_MATCH_PAGE: "Judul pada Halaman Tonton Pertandingan",
  ENTER_TITLE_FOR_WATCH_THE_MATCH_PAGE: "Petunjuk: Masukkan judul untuk Halaman Tonton Pertandingan",
  WATCH_MATCH_TAGLINE: "Tagline Tonton Pertandingan",
  BACKGROUND_IMAGES: "Gambar Latar Belakang",
  DESKTOP_BACKGROUND_IMAGE: "Gambar Latar Belakang Desktop",
  MOBILE_BACKGROUND_IMAGE: "Gambar Latar Belakang Mobile",

  SPECIAL_PRIZE_TITLE: "Judul Hadiah Spesial",
  PRIZE_CATALOG_TITLE: "Judul Katalog Hadiah",
  BACKGROUND_IMAGE_LINK: "Tautan Gambar Latar Belakang",
  URL_WHEN_BACKGROUND_IMAGE_IS_CLICKED: "URL saat gambar latar belakang diklik",
  TITLE_AT_REWARD_PAGE: "Judul pada Halaman Hadiah",
  TITLE_AT_SPECIAL_PRIZE: "Judul pada Hadiah Spesial",
  TITLE_AT_PRIZE_CATALOG: "Judul pada Katalog Hadiah",

  MOLA_SUBSCRIBER_REPORT: "Laporan Pelanggan Mola",
  SUBSCRIBER_TYPE: "Jenis Pelanggan",
  GET_DATA: "Ambil Data",
  ADMIN_ACTIONS: "Tindakan Admin",

  CREATE_NEXT_MONTHS_LEADERBOARD: "Buat Papan Peringkat Bulan Berikutnya",
  MONTH_BEFORE: "Bulan Sebelum",
  MONTH_AFTER: "Bulan Setelah",
  CREATE: "Buat",
  PREDICTION_AND_POINTS_REPORT: "Laporan Prediksi dan Poin",
  CHOOSE_EVENT_FOR_GET_REPORT: "Pilih Acara untuk Mendapatkan Laporan",

  REAL_TIME_DUEL_MONITORING: "Pemantauan Duel Waktu Nyata",
  REAL_TIME_DUEL_MONITORING_NOT_ACTIVE: 'Saat ini tidak ada Acara yang aktif',

  PLAYER_INSIGHT_REPORTS: 'Laporan Wawasan Pemain',
  PLAYER_NEW_REPORT: 'Pemain Baru Mingguan',
  PLAYER_REDEMPTION_REPORT: 'Penukaran Poin Mingguan',

  CLICK_ON_A_BOX_TO_GET_DUEL_DETAILS: "Klik pada kotak untuk mendapatkan detail Duel",
  DUELS_ACCEPTED: "Duel Diterima",
  DUEL_INVITES_PENDING: "Undangan Duel Tertunda",
  DUELS_REJECTED: "Duel Ditolak",
  WEEKLY_DUEL_SUMMARY_REPORT: "Laporan Ringkasan Duel Mingguan",
  SELECT_AN_EVENT: "Pilih Acara",
  LOAD_SUMMARY_REPORT: "Muat Laporan Ringkasan",
  LOAD_DASHBOARD_REPORT: 'Muat Laporan Dasbor',
  LOAD_ALL_TIME_REPORT: 'Muat Laporan Sepanjang Waktu',
  DOWNLOAD_EXCEL: "Unduh Excel",
  MONTHLY_LEADERBOARD_DEFAULT_VIEW: "Tampilan Default Papan Peringkat Bulanan",
  LEADERBOARD_CONFIGURATION: "Konfigurasi Papan Peringkat",
  REPORT_DETAIL_PER_USER: "Laporan Detail Per Pengguna",
  PREDICTION_WINNERS_REPORT: "Laporan Pemenang Prediksi",
  CHOOSE_EVENT_FOR_THE_REPORT: "Pilih Acara untuk Laporan",
  CHOOSE_FIGHT_FOR_THE_REPORT: "Pilih Pertarungan untuk Laporan",
  DUEL_DASHBOARD: "Dasbor Duel",
  WATCH_THE_MATCH_MANAGEMENT: "Manajemen Tonton Pertandingan",
  ADMIN_TASKS: "Tugas Admin",
  WEEKLY_DUEL_SUMMARY: "Ringkasan Duel Mingguan",
  POINT_CORRECTION: "Koreksi Poin",
  REPORTING: "Laporan",
  PREDICTION_ANALYTICS: "Analisis Prediksi",
  SUBSCRIBER_REPORT: "Laporan Pelanggan",
  LEADERBOARD_CONFIG: "Konf. Papan Peringkat",
  DESKTOP_BACKGROUND_IMAGE_URL: "Tautan Gambar Latar Belakang Desktop",
  MOBILE_BACKGROUND_IMAGE_URL: "Tautan Gambar Latar Belakang Mobile",
  TAGLINE_AT_WATCH_MATCH: "Tagline pada Tonton Pertandingan",
  AVERAGE_PREDICTION_TIME: "Rata-rata Waktu Prediksi Semua Pengguna",
  CHOOSE_EVENT_FOR_GET_REPORT_BR: "Pilih Acara untuk <br/> Mendapatkan Laporan",

  SHOWING_LAST_ITEMS: 'Menampilkan {x} item terakhir',
  TYPE_TO_SEARCH_OR_FILTER: 'Ketik untuk Mencari atau Menyaring',
  SELECT_TYPE: "Pilih Tipe",
  EVENT_DETAILS: "Detail Acara",
  SELECT_ADD_FIGHTS: "Pilih/Tambah Pertarungan",
  LOAD_FIGHTER_PROFILES: "Muat Profil Petarung",
  BACK: 'Kembali',
  CANCEL: 'Batalkan',
  DUEL_FILTER: 'Klik pada kotak untuk memfilter Detail Duel',
  CREATE_EVENT_PREDICTION_GAME: "Buat Permainan Prediksi Acara",
  LOAD_FIGHTER_PROFILES_HINT: "Untuk memuat semua Profil Petarung, mereka perlu dimuat dari API eksternal.<br> Silakan lanjutkan dengan mengklik \"Dapatkan Profil Petarung\".",
  GET_FIGHTER_PROFILES: "Dapatkan Profil Petarung",
  CONTINUE_ADD_FIGHTER_PROFILES: "Lanjutkan Tambah Profil Petarung",
  CREATE_ANOTHER_EVENT: "Buat Acara Lain",
  MANAGE_THIS_EVENT_IN_EVENTS: "Anda dapat Mengelola acara ini di 'Acara'.",
  THIS_EVENT: "Acara ini.",
  EVENT_CREATED: "ACARA DIBUAT",
  SELECT_AN_UFC_EVENT: "Pilih Acara UFC",
  MULTIPLIER_MAIN_CARDS: "Pengganda Kartu Utama",
  MULTIPLIER_PRELIMS: "Pengganda Pra-pertarungan",
  MULTIPLIER_FEATURED_SPECIAL: "Pengganda Unggulan/Spesial",
  FIGHTS_SELECTED_FOR_PREDICTION_GAMES: "Pertarungan yang Dipilih untuk Permainan Prediksi",
  FIGHTS: "Pertarungan",
  NR_OF_ROUNDS: "Jumlah Ronde",
  PREDICTION_GAME_SETUP_SUMMARY: "Ringkasan Pengaturan Permainan Prediksi",
  START_DATE: "Tanggal Mulai",
  END_DATE: "Tanggal Berakhir",
  VENUE: "Tempat",
  SHOW_ON_BROADCAST_SCHEDULE_FROM: "Tampilkan di Jadwal Siaran Dari",
  PREDICTION_SUBMISSION_OPEN_FROM: "Pengiriman Prediksi Dibuka Dari",
  MAKE_SPECIAL_FIGHT: "Jadikan Pertarungan Spesial",
  NR_OF_ROUNDS_SCHEDULED: "Jumlah Ronde yang Dijadwalkan",
  EVENT_NAME: "Nama Acara",
  EVENT_TYPE: "Tipe Acara",
  UPLOAD_SPONSOR_LOGO: "Unggah Logo Sponsor",
  UPDATE_FIGHT_TO_SPECIAL: "Perbarui Pertarungan ke Spesial",
  USE_THIS_IMAGE: "Gunakan Gambar ini",
  CREATE_FIGHTER_PROFILE: "Buat Profil Petarung",
  MAKE_THIS_A_SPECIAL_FIGHT: "Jadikan ini Pertarungan Spesial",
  ADD_SPONSOR_LOGO: "Tambahkan Logo Sponsor",
  EVENT_PUBLISH_END: "Akhir Publikasi Acara",
  EVENT_PUBLISH_START: "Mulai Publikasi Acara",
  ORDERING: "Pengurutan",
  PREDICTION_SUBMISSION_START: "Mulai Pengiriman Prediksi",
  PREDICTION_SUBMISSION_END: "Akhir Pengiriman Prediksi",
  BROADCAST_SCHEDULE_DETAILS: "Detail Jadwal Siaran",
  POINTS_ALLOCATION_AND_CONFIGURATION: "Alokasi dan Konfigurasi Poin",
  COMBINE_EVENTS: "Gabungkan Acara",
  SELECT_DATE: "Pilih Tanggal",
  SELECT_TIME: "Pilih Waktu",
  SCHEDULED_FIGHTS: "Pertarungan yang Dijadwalkan",
  SELECT_ALL: "Pilih Semua",
  HOW_TO_PLAY_CONTEST: 'Cara Ikut Kontes',
  HOW_TO_PLAY_MFP: 'CARA MAIN MOLA FIGHT PREDICTOR',
  HOW_TO_WIN_SUPERTRIP: 'CARA IKUT MFP SUPERTRIP:',
  HOW_TO_WIN_SUPERTRIP_TEXT: `
  <ol>
    <li>Peserta harus merupakan warga negara Indonesia yang sah dan berusia minimal 18 tahun.</li>
    <li>Setiap peserta hanya diperbolehkan memiliki satu entri per orang. Entri ganda oleh individu yang sama akan mengakibatkan diskualifikasi.</li>
    <li>Periode program MFP SuperTrip dimulai dari tanggal 1 Juni sampai dengan 30 Juni 2024.</li>
    <li>Poin diberikan berdasarkan keakuratan prediksi mengikuti cara main Mola Fight Predictor.</li>
    <li>Dalam hal terjadi seri untuk poin tertinggi dalam seminggu, aturan pemecah seri berikut berlaku:
        <ul>
            <li>Peserta dengan jumlah prediksi benar terbanyak.</li>
            <li>Jika masih seri, peserta yang menyerahkan prediksinya lebih awal.</li>
        </ul>
    </li>
    <li>Dua pemenang akan dipilih berdasarkan skor tertinggi mingguan di akhir bulan pada tanggal 30 Juni saat program berakhir, setelah hasil poin dari semua 5 event di bulan Juni tersedia.</li>
    <li>Poin tidak diakumulasi dari minggu ke minggu. Hanya skor tertinggi mingguan yang diambil sebagai pertimbangan.</li>
    <li>Semakin sering Anda membuat prediksi, semakin besar peluang Anda untuk mendapatkan poin tertinggi dalam seminggu.</li>
    <li>Penyelenggara berhak memverifikasi kelayakan paspor peserta berdasarkan masa habis berlaku paspor yaitu minimal Januari 2025.</li>
    <li>Visa akan disiapkan oleh penyelenggara bagi pemenang yang memerlukan.</li>
    <li>Penyelenggara berhak memverifikasi identitas peserta dan mendiskualifikasi peserta yang diduga memiliki entri ganda atau kolusi.</li>
    <li>Skor mingguan dan peringkat akan ditampilkan setiap minggunya pada Leaderboard.</li>
    <li>2 (dua) Peserta dengan poin tertinggi di minggu tersebut akan menerima email yang menginformasikan skor yang mereka dapatkan. Email akan dikirimkan H+2 pertandingan dilaksanakan.</li>
    <li>Penyelenggara berhak mengubah atau menghentikan program kapan saja karena keadaan yang tidak terduga. Setiap perubahan akan segera diberitahukan kepada peserta.</li>
    <li>Hadiah perjalanan ke Perth untuk acara UFC termasuk tiket pesawat pulang pergi kota asal - Perth, akomodasi hotel selama perjalanan, dan tiket event UFC.</li>
    <li>Hadiah berlaku untuk 1 (satu) orang dan tidak dapat dipindahtangankan atau ditukar dengan uang tunai.</li>
    <li>Untuk pertanyaan atau dukungan terkait kontes, peserta dapat menghubungi support@mola.tv.</li>
</ol>

  `,
  DUELS_EXPIRED: 'Duel Batal Otomatis',

   // PLAYER REPORTS
   SELECT_DATES: 'Pilih Tanggal Dari - Sampai',
   WEEKLY_REDEMPTION_REPORT: 'Poin Mingguan yang Ditebus dan Belum Ditebus',
   LOAD_REDEMPTION_REPORT : 'Muat Laporan Penukaran',
   TOTAL_UNREDEEMED_POINTS: 'Total Poin Belum Ditebus',
   TOTAL_REDEEMED_POINTS: 'Total Poin Ditebus',
   TOTAL_REDEEMED_ORDERS: 'Total Pesanan Penukaran',
   TOTAL_REDEEMED_ORDERS_QUANTITY: 'Jumlah Barang yang Ditebus',
   PLAYER_REPORT_FILTER: 'Klik pada kotak untuk memfilter detail',
   WEEKLY_NEW_PLAYER_REPORT: 'Laporan Pemain Baru Mingguan',
   TOTAL_PLAYERS: 'Total Pemain',
   TOTAL_NEW_PLAYERS: 'Total Pemain Baru',
   TOTAL_NEW_USERS: 'Total User Baru',

   // Reporting Dashboard
   LOADING_REPORTING_DASHBOARD: 'Memuat Dasbor Pelaporan',
   REPORTING_DASHBOARD_NAME: 'Dasbor Mingguan Otomatis',

}
export default IDN;
